"use client";
import RoundedButton from "@/components/inputs/buttons/roundedButton";
import { ComponentProps, ReactNode, useState } from "react";
import clsx from "clsx";
import { FieldValues, FormState, UseFormReset } from "react-hook-form";
import { renderTextWithNewlines } from "@/utils/renderTextWithNewlines";
import { useToastFormErrors } from "@/utils/useToastFormErrors";
import { ErrorBoundary } from "react-error-boundary";

export type formInput = {
	label?: string;
	description?: ReactNode;
	className?: string;
	hidden?: boolean;
	children: ReactNode;
};

export function FormInput({
	label,
	description,
	className,
	hidden,
	children,
}: formInput) {
	const hiddenChildren = hidden ? null : children;
	return (
		<div
			className={clsx(
				{
					hidden,
				},
				className
			)}
		>
			<label className={clsx("text-foreground flex flex-col")}>
				{label && renderTextWithNewlines(label)}
				<h3
					className={clsx("text-muted-foreground", {
						hidden: description === undefined,
					})}
				>
					{description}
				</h3>
				<ErrorBoundary
					FallbackComponent={({ error }) => (
						<div className="text-destructive">{error}</div>
					)}
				>
					{hiddenChildren}
				</ErrorBoundary>
			</label>
		</div>
	);
}

export type FormModalProps<T extends FieldValues> = {
	onCancel?: () => void;
	title: string;
	cancelLabel?: string;
	submitLabel: string;
	onSubmit: ComponentProps<"form">["onSubmit"];
	inputs: ReactNode[];
	formState?: FormState<T>;
	isDestructive?: boolean;
	resetOnCancel?: boolean;
	reset?: UseFormReset<T>;
	style?: ComponentProps<"form">["style"];

	children?: ReactNode;
} & ComponentProps<"form">;

export default function FormModal<T extends FieldValues>({
	onCancel,
	title,
	cancelLabel = "Cancel",
	submitLabel,
	onSubmit,
	inputs,
	isDestructive,
	resetOnCancel,
	reset,
	style,
	formState,
	className,

	...formProps
}: FormModalProps<T>) {
	useToastFormErrors(formState);

	const [isProcessing, setIsProcessing] = useState(false);
	return (
		<form
			style={style}
			className={clsx("p-6 bg-black", className)}
			onSubmit={async (e) => {
				e.preventDefault();

				setIsProcessing(true);
				try {
					if (onSubmit) await onSubmit(e);
				} finally {
					setIsProcessing(false);
				}
			}}
			{...formProps}
		>
			<div className="text-4xl mb-6">{title}</div>

			<div
				className={clsx("grid space-y-4 gap-x-2 items-end", {
					"grid-cols-2": inputs.length >= 4,
				})}
			>
				{inputs}
			</div>

			<div className="flex space-x-2">
				{onCancel && (
					<RoundedButton
						type="button"
						className="mt-6 w-full"
						onClick={() => {
							onCancel();
							if (resetOnCancel && reset) reset();
						}}
					>
						{cancelLabel}
					</RoundedButton>
				)}
				<RoundedButton
					type="submit"
					className="mt-6 w-full"
					colored={isDestructive ? "red" : "blue"}
					isProcessing={isProcessing}
				>
					{submitLabel}
				</RoundedButton>
			</div>
		</form>
	);
}
